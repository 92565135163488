import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['progress', 'article']

  update() {
    const viewportH = window.innerHeight
    const scrollTop = document.documentElement.scrollTop
    const { height, top } = this.articleTarget.getBoundingClientRect()
    const articleY = top + window.scrollY
    const articleEnd = height + articleY
    const progressEnd = articleEnd - viewportH
    const progress = (scrollTop / progressEnd) * 100
    const barProgress = progress > 100 ? 100 : progress
    this.progressTarget.value = barProgress
  }
}
