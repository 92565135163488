import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['applyBox']
  static values = { scrolledToEnd: { type: Boolean, default: false } }

  connect() {
    if (window.innerWidth >= 768) return

    this.toggleBottomBar()
  }

  toggleBottomBar(_event) {
    if (window.innerWidth >= 768) return

    const rect = this.applyBoxTarget.getBoundingClientRect()
    const inViewport = rect.top <= window.innerHeight && rect.bottom >= 0
    const scrolledPast = rect.bottom < 0
    this.scrolledToEndValue = inViewport || scrolledPast
  }
}
