export const parameterize = (str) => {
  return str
    .trim()
    .toLowerCase()
    .replace(/[\s.]/g, '-')
    .replace(/[^a-zA-Z0-9 -]/, '')
}

export const shuffle = (a) => {
  let j, x, i
  i = a.length - 1

  while (i > 0) {
    j = Math.floor(Math.random() * (i + 1))
    x = a[i]
    a[i] = a[j]
    a[j] = x
    i--
  }

  return a
}
