import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'errorMessage']
  static values = { thankYouMessageShown: { type: Boolean, default: false }}

  showError() {
    this.errorMessageTarget.classList.remove('opacity-0', '-translate-x-2')
  }

  showSuccess() {
    this.errorMessageTarget.classList.add('opacity-0', 'scale-90')
    this.thankYouMessageShownValue = true
  }

  submit(event) {
    event.preventDefault()
    const formData = new FormData(this.formTarget)

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString()
    })
      .then(response => {
        if (!response.ok) this.showError()
        this.showSuccess()
      })
      .catch((_error) => this.showError())
  }
}
