
        import * as module0 from './controllers/floating_logos_controller.js';import * as module1 from './controllers/form_controller.js';import * as module2 from './controllers/job_controller.js';import * as module3 from './controllers/nav_controller.js';import * as module4 from './controllers/page_progress_controller.js'
        const modules = {
            "./controllers/floating_logos_controller.js": module0,
            "./controllers/form_controller.js": module1,
            "./controllers/job_controller.js": module2,
            "./controllers/nav_controller.js": module3,
            "./controllers/page_progress_controller.js": module4,
        };
        export default modules;
      